import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import Page from 'components/page';

import { useNavigationContext } from 'contexts/navigation-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpaceStationMoonAlt } from '@fortawesome/pro-solid-svg-icons';

import useStyles from 'apputil/page-styles';

import { Grid, Typography } from '@material-ui/core';

const LazyFooter = loadable(() => import('components/footer'));

const NotFoundPage = () => {
  const styles = useStyles();

  const { four } = useNavigationContext();

  useEffect(() => {
    four();
    // eslint-disable-next-line
  }, []);

  return (
    <Page title="404: Not found" >
      <Grid spacing={2}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={10}>
          <Typography variant="h3" component="h1" color="secondary" className={styles.fourFourHeader}>
            404 NOT FOUND
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <div className={styles.fourFourContainer}>
            <FontAwesomeIcon icon={faSpaceStationMoonAlt} className={styles.fourFourIcon}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography variant="h5" component="p" color="error" className={styles.fourFourMessage}>
           welcome to the dark side
          </Typography>
        </Grid>
      </Grid>
      <LazyFooter />
    </Page>
  );
};
export default NotFoundPage;
